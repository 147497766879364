// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-drivers-improvement-js": () => import("./../../../src/pages/drivers-improvement.js" /* webpackChunkName: "component---src-pages-drivers-improvement-js" */),
  "component---src-pages-drivers-manual-js": () => import("./../../../src/pages/drivers-manual.js" /* webpackChunkName: "component---src-pages-drivers-manual-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-online-training-js": () => import("./../../../src/pages/online-training.js" /* webpackChunkName: "component---src-pages-online-training-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

